<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                        <b-row>
                                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <ValidationProvider name="Crop Name" vid="crop_name" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="3"
                                                        label-for="crop_name"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('bsri_config.crop_name_en') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        id="crop_name"
                                                        v-model="cropData.crop_name"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xs="12" sm="12" md="6" lg="12" xl="12">
                                                <ValidationProvider name="Crop Name (Bn)" vid="crop_name_bn" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="3"
                                                        label-for="crop_name_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('dae_config.crop_name_bn')}} <span class="text-danger">*</span>
                                                    </template>
                                                        <b-form-input
                                                        id="crop_name_bn"
                                                        v-model="cropData.crop_name_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xs="12" sm="12" md="6" lg="12" xl="12">
                                                <ValidationProvider name="Description" vid="description">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="3"
                                                        label-for="description"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('dae_config.crop_description_en')}}
                                                    </template>
                                                        <b-form-input
                                                        id="description"
                                                        v-model="cropData.description"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xs="12" sm="12" md="6" lg="12" xl="12">
                                                <ValidationProvider name="Description (Bn)" vid="description_bn">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="3"
                                                        label-for="description_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('dae_config.crop_description_bn')}}
                                                    </template>
                                                        <b-form-input
                                                        id="description_bn"
                                                        v-model="cropData.description_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xs="12" sm="12" md="6" lg="12" xl="12">
                                                <ValidationProvider name="Price" vid="price" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="3"
                                                        label-for="price"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.price')}} <span class="text-danger">*</span>
                                                    </template>
                                                        <b-form-input
                                                        id="price"
                                                        v-model="cropData.price"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { cropnameStore, cropnameUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getEditingData()
            this.cropData = tmp
        }
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            cropData: {
               crop_name: '',
               crop_name_bn: '',
               crop_description: '',
               crop_description_bn: '',
               price: ''
            },
            grant_types: [
                {
                    value: 1,
                    text: 'National',
                    text_bn: 'জাতীয়'
                },
                {
                    value: 2,
                    text: 'International',
                    text_bn: 'আন্তর্জাতিক'
                }
            ]
        }
    },
    watch: {
    },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    computed: {
        millTypeList: function () {
            const millTypeList = this.$store.state.incentiveGrant.commonObj.millTypeList
            return millTypeList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        grantTypeList: function () {
            return this.grant_types.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        }
    },
    methods: {
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${cropnameUpdate}/${this.id}`, this.cropData)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, cropnameStore, this.cropData)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
